import React, { createContext, useState, useContext } from 'react';
import imageWhatsIA from '../assets/chatbotWhatss.png'
const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([
    {
      id: 1,
      title: 'Simple Robot',
      price: "R$ 99.99",
      discount: 'Special discount for the first month',
      afterDiscount: 'After the first month, the price returns to R$ 99.99',
      access: 'Full access with a 7-day money-back guarantee.',
      description: 'Robô Simples Description',
      image: imageWhatsIA,
      features: [
        'Sequential Robot',
        'Menu Robot',
        'Highly customizable',
        'Customer History',
        'Dashboard with data insights'
      ],
      link : ''
    },
    {
      id: 2,
      title: 'Numeric Delivery Robot',
      price: "R$ 99.99",
      discount: 'Special discount for the first month',
      afterDiscount: 'After the first month, the price returns to R$ 99.99',
      access: 'Automation for water and gas deliveries with up to 5 different products.',
      description: 'Robô Delivery Description',
      image: imageWhatsIA,
      features: [
        'Inventory control',
        'Payment notification',
        'Integration with receipt printer',
        'QR Code system on receipt',
        'Dashboard with data insights'
      ],
      link : ''
      
    },
    {
      id: 3,
      title: 'Complete Version',
      price: "R$ 149.99",
      discount: 'Special discount for the first month',
      afterDiscount: 'After the first month, the price returns to R$ 149.99',
      access: 'Full access with a 7-day money-back guarantee.',
      description: 'Robô IA Description',
      image: imageWhatsIA,
      features: [
        'Human-like AI customer service',
        'Audio understanding',
        'Mass messaging with multimedia',
        'Unlimited sending',
        'Context understanding',
        'AI agent that completes missions',
        'Dashboard with data insights'
      ],
       tag: 'bestseller',
       link : ''
    }
  ]);

  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => useContext(ProductContext);
