import React from 'react';
import '../css/PricingCard.css';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PricingCard = ({ index, id, title, price, discount, afterDiscount, access, features, image, tag }) => {
  let direction;
  const navigate = useNavigate();
  const { t } = useTranslation();

  switch (index) {
    case 0:
      direction = 'right';
      break;
    case 1:
      direction = 'up';
      break;
    case 2:
      direction = 'left';
      break;
    default:
      direction = 'up';
  }

  const handleSubscribe = () => {
    navigate(`/product_details/${id}`); // Navigate with product id
  };

  return (
    <motion.div
      variants={fadeIn(direction, 0.2)}
      initial='hidden'
      whileInView='show'
      viewport={{ once: false, amount: 0.7 }}
      className='pricing-card'
    >
      {tag && <div className='tag'>{t(tag)}</div>} {/* Adiciona a tag traduzida se fornecida */}
      <h2 className='pricing-title'>{title}</h2>
      <p className='pricing-price'>{price}</p>
      <p className='pricing-discount'>{discount}</p>
      {/* <p className='pricing-after'>{afterDiscount}</p>
      <p className='pricing-access'>{access}</p> */}
      <ul className='pricing-features'>
        {features.map((feature, idx) => (
          <li key={idx}>{feature}</li>
        ))}
      </ul>
      <button onClick={handleSubscribe} className='btn primary-btn'>Ver mais</button>
    </motion.div>
  );
};

export default PricingCard;
