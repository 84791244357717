import React from 'react';

import Logo from '../assets/logo_baymax.png';
import { useTranslation } from 'react-i18next';
const Header = () => {

  const { t } = useTranslation();
  return (
    <div class="header">
      <img  rel="preload" src={Logo} alt="Logo" class="logo"></img>
      <nav class="navh">
          <ul>
          <li><a href="#home">{t('homenav')}</a></li>
          <li><a href="#about">{t('aboutnav')}</a></li>
          <li><a href="#services">{t('servicesnav')}</a></li>
          <li><a href="#work">{t('productsnav')}</a></li>
          <li><a href="#contact">{t('contactnav')}</a></li>
          </ul>
      </nav>
    </div>
  )
  
};

export default Header;
