import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import icon from '../src/assets/logo_baymax.png';
import LandingPage from '../src/pages/LandingPage.js';
import ProductDetails from '../src/pages/ProductDetails.js';

const App = () => {
  const idiomaNavegador = window.navigator.language;
  console.log(idiomaNavegador);

  return (
    <Router>
      <div className='bg-no-repeat bg-cover overflow-hidden bg'>
        <Helmet>
          <link rel="icon" href={icon}></link>
        </Helmet>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/product_details/:id" element={<ProductDetails />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
